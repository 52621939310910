<template>
  <div>
    Loading, please wait. You will be redirected.
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import store from '@/store'

export default {
  name: 'OauthVue',
  computed: {
    network() {
      return this.$route.params.network
    },
    code() {
      switch (this.network) {
        case 'google':
          return this.$route.query.code
        default:
          return null
      }
    },
  },
  created() {
    this.showLoader(true)
  },
  mounted() {
    const redirectUrl = `${window.location.origin}${this.$router.resolve({
      name: 'login-oauth2',
      params: { network: this.network },
    }).href}`
    store.dispatch('auth/loginSocial', { code: this.code, network: this.network, redirectUrl }).catch(e => {
      this.showLoader(false)
      Swal.fire({
        title: 'Login Failed',
        text: e.status === 401 ? 'Account is not registered as Affiliate.' : 'There was an error processing your login. Please try again later.',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'login' })
      })
    })
  },
}
</script>

<style scoped>

</style>
